import React from "react";
import { AnimatedSection, AnimatedDiv, AnimatedH2, AnimatedH3, AnimatedP, AnimatedImg } from "./AnimatedComponents";

export const TokenomicsSection = () => {
  return (
    <AnimatedSection className="bg-transparent text-white py-24 mx-auto border-t-2 border-white" id="tokenomics" duration={0.5} delay={0.05}>
      <AnimatedDiv className="container mx-auto max-w-[1024px] py-40 px-20 lg:px-60" duration={0.5} delay={0.1}>
        <AnimatedH2 className="text-4xl md:text-5xl lg:text-6xl font-bold text-center mb-24" duration={0.5} delay={0.15}>Tokenomics</AnimatedH2>
        <AnimatedImg src="/dog.png" alt="Dog" className="mx-auto mb-10" style={{ height: "240px" }} duration={0.5} delay={0.2} />
        <AnimatedDiv className="flex flex-col gap-10" duration={0.5} delay={0.25}>
          <AnimatedDiv duration={0.5} delay={0.3}>
            <AnimatedH3 className="text-2xl md:text-3xl lg:text-4xl font-bold text-center mb-24" duration={0.35} delay={0.05}>Overview</AnimatedH3>
            <AnimatedP className="text-lg md:text-xl lg:text-2xl opacity-75 text-justify" duration={0.35} delay={0.1}>HTCN token serves as the native currency of the HeartChain Inu ecosystem. It will be used for minting and purchasing Flirt, FM2TM, participating in platform governance through voting on key decisions, and rewarding users for engagement and contributions to the community.</AnimatedP>
          </AnimatedDiv>
          <AnimatedDiv duration={0.5} delay={0.4}>
            <AnimatedH3 className="text-2xl md:text-3xl lg:text-4xl font-bold text-center mb-5" duration={0.35} delay={0.05}>Token Distribution and Unlock Schedule</AnimatedH3>
            <AnimatedImg src="/tokenomics.png" alt="Tokenomics" className="hidden sm:block mx-auto" duration={0.5} delay={0.2} />
            <AnimatedImg src="/tokenomics-mobile.png" alt="Tokenomics" className="block sm:hidden mx-auto my-24" duration={0.5} delay={0.25} />
            <AnimatedP className="mt-5 text-justify" duration={0.35} delay={0.3}>
              The total available supply at launch will therefore be a minimum of 100,000,000 and a maximum of about 220,000,000 HTCN with a 5% of the 250,000,000 remaining locked staking supply disbursed annually, with monthly rewards adjusted accordingly each year. This way, we promote a high Total-Value-Locked (TVL) as well as ensuring that in each year the total available rewards will be progressively less.
            </AnimatedP>
          </AnimatedDiv>
          <AnimatedDiv duration={0.5} delay={0.4}>
            <AnimatedH3 className="text-2xl md:text-3xl lg:text-4xl font-bold text-center mb-5" duration={0.35} delay={0.05}>Dynamic Deflationary Mechanism</AnimatedH3>
            <AnimatedP className="text-lg md:text-xl lg:text-2xl opacity-75 text-justify" duration={0.35} delay={0.1}>
              There is a deflationary mechanism built in as the value locked in Flirt tokens is burned when their contact quota is used up or if it is minted into an FM2TM. This additionally functions as a hedge against price volatility, ensuring stable minting costs and preventing the dilution of holders’ staking rewards.
            </AnimatedP>
          </AnimatedDiv>
        </AnimatedDiv>
      </AnimatedDiv>
    </AnimatedSection>
  );
};
