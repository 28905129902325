import React from "react";
import { PresaleSection } from "../components/home/PresaleSection";
import { IntroductionSection } from "../components/home/IntroductionSection";
import { TokenomicsSection } from "../components/home/TokenomicsSection";
import { PresaleStageSection } from "../components/home/PresaleStageSection";
import { RoadmapSection } from "../components/home/RoadmapSection";

export const HomePage = () => {
  return (
    <>
      <div className="flex flex-col lg:flex-row gap-10">
        <div className="w-full lg:w-1/1.75 h-full">
          <IntroductionSection />
        </div>
        <div className="w-full lg:w-1/1.75 h-full">
          <PresaleSection />
        </div>
      </div>
      <TokenomicsSection />
      <PresaleStageSection />
      <RoadmapSection />
    </>
  );
};
