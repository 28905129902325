import React from "react";
import { AnimatedSection, AnimatedDiv, AnimatedH2, AnimatedStrong, AnimatedP, AnimatedImg } from "./AnimatedComponents";

export const PresaleStageSection = () => {
  return (
    <AnimatedSection id="presale-stage" className="bg-transparent text-white py-20 border-t-2 border-white px-20 lg:px-60" duration={0.5} delay={0.05}
      style={{
        backgroundImage: 'url("/background4.jpg")',
        backgroundSize: "cover",
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
        width: "100%",
        height: "auto",
      }}
    >
      <AnimatedDiv className="container mx-auto px-4" duration={0.5} delay={0.1}>
        <AnimatedH2 className="text-4xl md:text-5xl lg:text-6xl font-bold text-center mb-10" duration={0.5} delay={0.15}>
          Presale Stage
        </AnimatedH2>
        <AnimatedImg src="/dog.png" alt="Dog" className="mx-auto mb-10" style={{ height: "240px" }} duration={0.5} delay={0.2} />
        <AnimatedDiv className="flex flex-col gap-5" duration={0.5} delay={0.25}>
          <AnimatedP className="text-lg md:text-xl lg:text-2xl opacity-75 text-justify w-full lg:w-1/2 mx-auto" duration={0.5} delay={0.3}>
            Participate in our presale stage to secure your HTCN tokens before the official launch. As an early adopter, you will have the opportunity to benefit from higher staking rewards, exclusive NFTs, and community engagement opportunities.
          </AnimatedP>
          <AnimatedDiv className="mt-10 flex flex-wrap justify-center gap-10" duration={0.5} delay={0.35}>
            {[
              { src: "/early-access.png", alt: "Early Access", title: "Early Access", description: "Gain access to the HeartChain Inu platform before the official launch." },
              { src: "/higher-rewards.png", alt: "Higher Rewards", title: "Higher Rewards", description: "Enjoy higher staking rewards during the presale stage." },
              { src: "/exclusive-nfts.png", alt: "Exclusive NFTs", title: "Exclusive NFTs", description: "Mint and collect exclusive NFTs available during the presale." },
              { src: "/community-engagement.png", alt: "Community Engagement", title: "Community Engagement", description: "Join and participate in our growing community of early adopters." },
            ].map(({ src, alt, title, description }, index) => (
              <AnimatedDiv key={index} className="flex flex-col items-center rounded-lg border border-gray-300 bg-gray-900/50 p-8 w-full sm:w-1/2 md:w-1/3 lg:w-1/4 text-center" duration={0.5} delay={0.4 + (index * 0.1)}>
                <AnimatedImg src={src} alt={alt} className="w-[96px] h-[96px] mb-5" duration={0.35} delay={0} />
                <AnimatedStrong className="text-2xl font-bold mb-2" duration={0.35} delay={0.05}>
                  {title}
                </AnimatedStrong>
                <AnimatedP className="opacity-75" duration={0.35} delay={0.1}>
                  {description}
                </AnimatedP>
              </AnimatedDiv>
            ))}
          </AnimatedDiv>
        </AnimatedDiv>
      </AnimatedDiv>
    </AnimatedSection>
  );
};
