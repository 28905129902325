import React from "react";
import { AnimatedSection, AnimatedDiv, AnimatedH2, AnimatedP, AnimatedStrong, AnimatedImg } from "./AnimatedComponents";

export const IntroductionSection = () => {
  return (
    <AnimatedSection id="introduction" className="text-white py-40 px-20 lg:px-60" duration={0.75} delay={0.5}
      style={{
        backgroundImage: 'url("/background1.jpg")',
        backgroundSize: "cover",
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
        width: "100%",
        height: "auto",
      }}
    >
      <AnimatedDiv className="container mx-auto px-4" duration={0.5} delay={1}>
        <AnimatedH2 className="text-4xl md:text-5xl lg:text-6xl font-bold text-center mb-16" duration={0.5} delay={1.2}>
          Introduction
        </AnimatedH2>
        <AnimatedDiv className="flex flex-col gap-5" duration={0.5} delay={1.4}>
          <AnimatedP className="text-lg md:text-xl lg:text-2xl opacity-75 text-justify max-w-480px" duration={0.5} delay={1.6}>
            HeartChain Inu (HTCN) is an innovative cryptocurrency project designed to revolutionize the online matchmaking landscape by leveraging the power of blockchain technology and non-fungible tokens (NFTs). Built on the Sui blockchain, HeartChain Inu aims to create a decentralized platform where users can connect, communicate, and engage in meaningful relationships through unique digital assets. The platform allows users to mint personalized NFTs that serve as their digital identity within the ecosystem and means of contact on the HeartChain Inu platform, enhancing authenticity and trust in online interactions, gamifying the courting process, and allowing individuals of both sexes to monetize the ability to chat with each other and profit from their courtship experience via staking rewards and NFT sales. The Platform will also offer the option of local advisors to provide wisdom and guidance, helping users avoid the myriad pitfalls that solo-flying “Passport bros” often fall victim to.
          </AnimatedP>
        </AnimatedDiv>

        {/* Features Section */}
        <AnimatedDiv className="mt-20 flex flex-wrap justify-center gap-10" duration={0.5} delay={1.8}>
          {[
            { src: "/decentralized-platform.png", alt: "Decentralized Platform", title: "Decentralized Platform", description: "Built on the Sui blockchain for high performance and low latency." },
            { src: "/personalized-nfts.png", alt: "Personalized NFTs", title: "Personalized NFTs", description: "Users can mint NFTs representing their digital identity." },
            { src: "/enhanced-authenticity.png", alt: "Enhanced Authenticity", title: "Enhanced Authenticity", description: "Facially verified NFTs to ensure user authenticity." },
            { src: "/gamified-experience.png", alt: "Gamified Experience", title: "Gamified Experience", description: "Users can monetize their interactions." },
            { src: "/local-advisors.png", alt: "Local Advisors", title: "Local Advisors", description: "Guidance for users to avoid common pitfalls." },
          ].map(({ src, alt, title, description }, index) => (
            <AnimatedDiv key={index} className="flex flex-col items-center rounded-lg border border-gray-300 bg-gray-900/50 p-8 w-full sm:w-1/2 md:w-1/3 lg:w-1/4 text-center min-w-180" duration={0.25} delay={0}>
              <AnimatedImg src={src} alt={alt} className="w-[96px] h-[96px] mb-5" duration={0.25} delay={0} />
              <AnimatedStrong className="text-2xl font-bold mb-2" duration={0.25} delay={0.1}>{title}</AnimatedStrong>
              <AnimatedP className="opacity-75" duration={0.25} delay={0.2}>{description}</AnimatedP>
            </AnimatedDiv>
          ))}
        </AnimatedDiv>
      </AnimatedDiv>
    </AnimatedSection>
  );
};
