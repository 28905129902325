import React from "react";

import "./layout.scss";
import { Header } from "./header";
import { Footer } from "./footer";

export const Layout = (props) => {
  return (
    <div className="layout-wrapper flex flex-col gap-20 pt-60 md:pt-80 lg:pt-60 pb-50">
      <div />
      <Header balance={props.balance} />
      {props.children}
      <Footer />
    </div>
  );
};
