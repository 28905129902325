import React from "react";
import { AnimatedSection, AnimatedDiv, AnimatedH2, AnimatedImg } from "./AnimatedComponents";

export const RoadmapSection = () => {
  return (
    <AnimatedSection id="roadmap" className="bg-transparent text-white py-20 border-t-2 border-white px-20 lg:px-60" duration={0.5} delay={0.05}>
      <AnimatedDiv className="container mx-auto px-4" duration={0.5} delay={0.1}>
        <AnimatedH2 className="text-4xl md:text-5xl lg:text-6xl font-bold text-center mb-10" duration={0.5} delay={0.15}>
          Roadmap
        </AnimatedH2>
        <AnimatedImg src="/roadmap.png" alt="" className="hidden md:block mx-auto drop-shadow-sm" duration={0.5} delay={0.2} />
        <AnimatedImg src="/roadmap-mobile.png" alt="" className="block md:hidden mx-auto my-24" duration={0.5} delay={0.25} />
      </AnimatedDiv>
    </AnimatedSection>
  );
};
