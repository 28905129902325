export const OBJECT_RECORD = {
    PACKAGEID:
        '0x7bd7d33491d0aef27f9e7aacbf3a9ea026af98d7e48a472fb285331b3e41c0d5',
    USERSTATE:
        '0xd2e63c80f0ba0473853135c5d3fe98943a3611bc9b8e71224cf623def90c00c2',
    ADMINCAP:
        '0x03a567f76b58a950c7e6c62cc3da37a7def9a208505fe80070d92da9f94cabc2', //admin auth
    TREASURY:
        '0x520428d3863d5fef9f5fe57e0034e65ebfd32c3a2d7e54cbbdfd36e4619dea9b',
    PRESALEPOOL:
        "0x892378ee1afafb5b5fd87ad48f9794628147d1935d3e2b9f929932c0ecb215a3",
    TOKENTREASURYCAP:
        '0xfc61a70b46113d46e53600ce755a3019490152e5336679e8c2e45300ac62f500',
    TOKEN:
        '0x1fc45b699b4d3369821263da781c1d6e52e6f8a0ddb93be60e3a6276aaaf2b23',
    ADDRESSZERO:
        '0x0000000000000000000000000000000000000000000000000000000000000000',
    CLOCKOBJECT:
        "0x0000000000000000000000000000000000000000000000000000000000000006",
    OWNERADDRESS:
        "0x13d8dc0b634bb5bd7d9fc40ef6a0d1a5ba0963c3aa64aa87445db7e90043376f",
    COIN_SUI: "0x2::sui::SUI"
    // PACKAGEID:
    //     '0xfbb4bc850e013ce73f2c624cd62fa47a3059aa503e71441863062e2475a2584a',
    // USERSTATE:
    //     '0x073ad2f12db6d4772841ef36263ddc70876a5512e159f10db92ba41590e302c9',
    // ADMINCAP:
    //     '0x21124b2abca6456bbd49cd1e7fbba15dd6e0f62a00400849a59e999ce8d02f66', //admin auth
    // TREASURY:
    //     '0x601554d6d35ace1392e22f19174a0c4919b326cc4391a0ed5a57fac131a70e20',
    // PRESALEPOOL:
    //     "0xb2b454fceb9572c12f6c949b89a762d1f522da713fae6f223ada7a9237a95dfe",
    // TOKENTREASURYCAP:
    //     '0xa10ba6556c03490bc57c56fbcfc8c50279f1ff37a239da07b8eb3de52c116ea7',
    // TOKEN:
    //     '0x332a8c10ffcf324ffa87e931f8d2bd2759dd7e6e0555f9fab1177d1557b3efc5',
    // ADDRESSZERO:
    //     '0x0000000000000000000000000000000000000000000000000000000000000000',
    // CLOCKOBJECT:
    //     "0x0000000000000000000000000000000000000000000000000000000000000006",
    // OWNERADDRESS:
    //     "0x13d8dc0b634bb5bd7d9fc40ef6a0d1a5ba0963c3aa64aa87445db7e90043376f",
    // COIN_SUI: "0x2::sui::SUI"
};

export const LAMPORT = 1000000000;
