import React, { FC } from 'react';
import { motion } from 'framer-motion';
import { sectionVariant, childVariant, imageVariant, buttonHoverVariant, tableRowVariant } from './animations';

interface AnimatedSectionProps {
  id?: string;
  className?: string;
  style?: React.CSSProperties;
  children: React.ReactNode;
  duration: number;
  delay: number;
}

const AnimatedSection: FC<AnimatedSectionProps> = ({ id, className, style, children, duration, delay }) => {
  return (
    <motion.section
      id={id}
      className={className}
      style={style}
      variants={sectionVariant}
      initial="hidden"
      whileInView="visible"
      transition={{ duration, delay }}
    >
      {children}
    </motion.section>
  );
};

interface AnimatedDivProps {
  className?: string;
  style?: React.CSSProperties;
  delay?: number;
  duration?: number;
  onClick?: () => void;
  children?: React.ReactNode;
}

const AnimatedDiv: FC<AnimatedDivProps> = ({ className, style, delay = 0, duration = 0, onClick, children }) => {
  return (
    <motion.div
      className={className}
      style={style}
      variants={sectionVariant}
      initial="hidden"
      whileInView="visible"
      transition={{ duration, delay }}
      onClick={onClick}
    >
      {children}
    </motion.div>
  );
};

const AnimatedH2: FC<AnimatedDivProps> = ({ className, style, delay = 0, duration = 0, onClick, children }) => {
  return (
    <motion.h2
      className={className}
      style={style}
      variants={sectionVariant}
      initial="hidden"
      whileInView="visible"
      transition={{ duration, delay }}
      onClick={onClick}
    >
      {children}
    </motion.h2>
  );
};

const AnimatedH3: FC<AnimatedDivProps> = ({ className, style, delay = 0, duration = 0, onClick, children }) => {
  return (
    <motion.h3
      className={className}
      style={style}
      variants={childVariant}
      initial="hidden"
      whileInView="visible"
      transition={{ duration, delay }}
      onClick={onClick}
    >
      {children}
    </motion.h3>
  );
};

const AnimatedP: FC<AnimatedDivProps> = ({ className, style, delay = 0, duration = 0, onClick, children }) => {
  return (
    <motion.p
      className={className}
      style={style}
      variants={childVariant}
      initial="hidden"
      whileInView="visible"
      transition={{ duration, delay }}
      onClick={onClick}
    >
      {children}
    </motion.p>
  );
};

interface AnimatedSpanProps {
  className?: string;
  style?: React.CSSProperties;
  delay?: number;
  duration?: number;
  onClick?: () => void;
  children?: React.ReactNode;
}

const AnimatedSpan: FC<AnimatedSpanProps> = ({ className, style, delay, duration, onClick, children }) => {
  if (typeof delay !== "undefined" && typeof duration !== "undefined") {
    return (
      <motion.span
        className={className}
        style={style}
        variants={childVariant}
        initial="hidden"
        whileInView="visible"
        transition={{ duration, delay }}
        onClick={onClick}
      >
        {children}
      </motion.span>
    );
  } else {
    return <span className={className} onClick={onClick}>{children}</span>;
  }
};

const AnimatedStrong: FC<AnimatedSpanProps> = ({ className, style, delay, duration, onClick, children }) => {
  if (typeof delay !== "undefined" && typeof duration !== "undefined") {
    return (
      <motion.strong
        className={className}
        style={style}
        variants={childVariant}
        initial="hidden"
        whileInView="visible"
        transition={{ duration, delay }}
        onClick={onClick}
      >
        {children}
      </motion.strong>
    );
  } else {
    return <strong className={className} onClick={onClick}>{children}</strong>;
  }
};

interface AnimatedImgProps {
  src: string;
  alt: string;
  className?: string;
  style?: React.CSSProperties;
  width?: string;
  height?: string;
  delay?: number;
  duration?: number;
}

const AnimatedImg: FC<AnimatedImgProps> = ({ src, alt, className, style, width, height, delay = 0, duration = 0 }) => {
  return (
    <motion.img
      src={src}
      alt={alt}
      width={width}
      height={height}
      className={className}
      style={style}
      variants={imageVariant}
      initial="hidden"
      whileInView="visible"
      transition={{ duration, delay }}
    />
  );
};

interface AnimatedInputProps {
  type: string;
  placeholder?: string;
  value: string;
  readOnly?: boolean;
  onClick?: () => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  step?: number;
  delay?: number;
  duration?: number;
}

const AnimatedInput: FC<AnimatedInputProps> = ({ type, placeholder, value, readOnly, onClick, onChange, className, step, delay = 0, duration = 0 }) => {
  return (
    <motion.input
      type={type}
      placeholder={placeholder}
      value={value}
      readOnly={readOnly}
      onClick={onClick}
      onChange={onChange}
      className={className}
      step={step}
      variants={childVariant}
      initial="hidden"
      whileInView="visible"
      transition={{ duration, delay }}
    />
  );
};

interface AnimatedButtonProps {
  type?: "button" | "submit" | "reset";
  onClick?: () => void;
  className?: string;
  children?: React.ReactNode;
  delay?: number;
  duration?: number;
}

const AnimatedButton: FC<AnimatedButtonProps> = ({ type = "button", onClick, className, children, delay = 0, duration = 0 }) => {
  return (
    <motion.button
      type={type}
      onClick={onClick}
      className={className}
      variants={buttonHoverVariant}
      initial="hidden"
      whileInView="visible"
      transition={{ duration, delay }}
    >
      {children}
    </motion.button>
  );
};

interface AnimatedTableProps {
  className?: string;
  style?: React.CSSProperties;
  delay?: number;
  duration?: number;
  children?: React.ReactNode;
}

const AnimatedTable: FC<AnimatedTableProps> = ({ className, style, delay = 0, duration = 0, children }) => {
  return (
    <motion.table
      className={className}
      style={style}
      variants={sectionVariant}
      initial="hidden"
      whileInView="visible"
      transition={{ duration, delay }}
    >
      {children}
    </motion.table>
  );
};

interface AnimatedTheadProps {
  className?: string;
  style?: React.CSSProperties;
  delay?: number;
  duration?: number;
  children?: React.ReactNode;
}

const AnimatedThead: FC<AnimatedTheadProps> = ({ className, style, delay = 0, duration = 0, children }) => {
  return (
    <motion.thead
      className={className}
      style={style}
      variants={sectionVariant}
      initial="hidden"
      whileInView="visible"
      transition={{ duration, delay }}
    >
      {children}
    </motion.thead>
  );
};

interface AnimatedTbodyProps {
  className?: string;
  style?: React.CSSProperties;
  delay?: number;
  duration?: number;
  children?: React.ReactNode;
}

const AnimatedTbody: FC<AnimatedTbodyProps> = ({ className, style, delay = 0, duration = 0, children }) => {
  return (
    <motion.tbody
      className={className}
      style={style}
      variants={sectionVariant}
      initial="hidden"
      whileInView="visible"
      transition={{ duration, delay }}
    >
      {children}
    </motion.tbody>
  );
};

interface AnimatedTrProps {
  className?: string;
  style?: React.CSSProperties;
  delay?: number;
  duration?: number;
  children?: React.ReactNode;
}

const AnimatedTr: FC<AnimatedTrProps> = ({ className, style, delay = 0, duration = 0, children }) => {
  return (
    <motion.tr
      className={className}
      style={style}
      variants={tableRowVariant}
      initial="hidden"
      whileInView="visible"
      transition={{ duration, delay }}
      custom={delay}
    >
      {children}
    </motion.tr>
  );
};

interface AnimatedThProps {
  className?: string;
  style?: React.CSSProperties;
  scope?: string;
  delay?: number;
  duration?: number;
  children?: React.ReactNode;
}

const AnimatedTh: FC<AnimatedThProps> = ({ className, style, scope, delay = 0, duration = 0, children }) => {
  return (
    <motion.th
      scope={scope}
      className={className}
      style={style}
      variants={childVariant}
      initial="hidden"
      whileInView="visible"
      transition={{ duration, delay }}
    >
      {children}
    </motion.th>
  );
};

interface AnimatedTdProps {
  className?: string;
  style?: React.CSSProperties;
  delay?: number;
  duration?: number;
  children?: React.ReactNode;
}

const AnimatedTd: FC<AnimatedTdProps> = ({ className, style, delay = 0, duration = 0, children }) => {
  return (
    <motion.td
      className={className}
      style={style}
      variants={childVariant}
      initial="hidden"
      whileInView="visible"
      transition={{ duration, delay }}
    >
      {children}
    </motion.td>
  );
};

export { AnimatedSection, AnimatedDiv, AnimatedH2, AnimatedH3, AnimatedP, AnimatedSpan, AnimatedStrong, AnimatedImg, AnimatedInput, AnimatedButton, AnimatedTable, AnimatedThead, AnimatedTbody, AnimatedTr, AnimatedTh, AnimatedTd };